html,
body,
#root {
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
}

.tmwygt-menu-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 1rem;
}

.tmwygt-menu-item {
  padding: 1rem;
}

.tmwygt-about-text {
  width: 60rem;
  margin: 5rem auto;
}

.tmwygt-avatar {
  height: 50px;
  width: 50px;
  background-color: var(--a-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  border-radius: 50%;
}

.tmwygt-animal-image {
  width: 80%;
  height: 80%;
  user-select: none;
}